import React from 'react';

const Logo = (props) => {
    return (
        <div id="logo">
            <a href="https://paretify.com/" target="_blank">
                <img src="/img/logo_white_no_txt.png" width="40" height="40" alt="paretify" className={props.toggler ? "active" : " "}></img>
                <h1>paretify</h1>
            </a>
            <h1>HVILKEN LØN SKAL DU FORHANDLE OM?</h1>
        </div>
    );
}

export default Logo;