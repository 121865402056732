import React, { useState } from 'react';
import { SliderContextConsumer } from './SliderContext'
import NumberFormat from 'react-number-format';

const SalaryInput = () => {


    const [salary, setsalary] = useState(0);

    function handleOnChange(e) {
        console.log(e)
    }


    return (

        <SliderContextConsumer>
            {context => (
                <NumberFormat
                    autoFocus
                    pattern="\d*"
                    onClick={(e) => { console.log(e.target); e.target.select() }}
                    id="lonInput"
                    className="numbers"
                    defaultValue={"yoyoyo"}
                    value={context.lonIput}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={' kr.'}
                    // displayType={'text'}
                    onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        context.setLonInput(value)
                    }}
                />
                // <input
                //     type="number"
                //     onChange={(e) => { context.setLonInput(e.target.value) }}
                //     value={context.lonInput} />
            )}
        </SliderContextConsumer>
    );
}

export default SalaryInput;