import './scss/App.scss';
import React, { useState, useEffect } from 'react'
import Slider from './components/Slider'
import data from './data'
import Switch from 'react-switch'
import SalaryInput from "./components/salaryInput";
import LonResult from './components/LonResult';
import Logo from './components/Logo';

// import NewJobResult from './components/NewJobResult';
import { SliderContextConsumer } from './components/SliderContext'


function App() {
  // const [sliderValue, setSlider] = useState(50)
  const [toggler, setToggler] = useState(false);
  const [slidersComps, setSlidersComps] = useState([]);
  const [changeMode, setChangeMode] = useState("loaded");
  const [init, setinit] = useState(false);

  // function inIframe() {
  //   try {
  //     return window.self !== window.top;
  //   } catch (e) {
  //     return true;
  //   }
  // }

  const inIframe = window.location !== window.parent.location ? true : false


  useEffect(() => {
    if (inIframe) {
      document.body.style.backgroundColor = "transparent";
    } else {
      document.body.style.backgroundColor = "#767A7A";
    }
  }, []);
  console.log(init)

  function handleToggler(e) {
    setToggler(e)
  }

  function handleInit() {
    setinit(true)
  }
  // console.log(toggler);

  useEffect(() => {
    const newSlidersComps = []
    data[toggler ? 1 : 0].forEach((el, i) => {
      console.log(el);
      newSlidersComps[i] = <Slider key={i} id={i} data={el} toggler={toggler} />
    })

    setSlidersComps(newSlidersComps);
    setChangeMode("loading")
    setTimeout(() => { setChangeMode("loaded") }, 1000)
    // console.log(slidersComps)
  }, [toggler])


  // function handleSlider(e) {
  //   setSlider(e.target.value);
  // }
  // console.log(data);
  return (

    <div id="appContainer" className={`${inIframe ? "iframe" : ""} ${init ? "" : "default"}`}>
      <div id="header">
        {!inIframe ?
          <Logo toggler={toggler} />
          : null}
        <div id="switch">
          <h2 className={!toggler ? "active" : ""}>Dit job</h2>
          <SliderContextConsumer>
            {context => (
              <Switch
                onChange={handleToggler}
                onLoad={context.switchDataSet(toggler)}
                onColor="#ffffff"
                offColor="#ffffff"
                className="switch"
                offHandleColor="#1c3557"
                onHandleColor="#1c3557"
                activeBoxShadow='0 0 2px 3px #A8DADD'
                // onHandleColor
                // width="100%"
                // height={!init ? 40 : 28}
                // width={!init ? 80 : 56}
                checked={toggler}
                uncheckedIcon={false}
                checkedIcon={false} />
            )
            }
          </SliderContextConsumer>
          <h2 className={toggler ? "active" : ""}>Nyt job</h2>
        </div>
        <div id="inputContainer">
          <h1>Nuværende løn (inkl. pension):</h1>
          <div>
            {init ? <SalaryInput /> : <input onClick={handleInit} id="lonInput" type="text" className="numbers default" defaultValue="indtast løn"></input>}
          </div>
        </div>
      </div>
      <div id="allSliders" className={changeMode}>
        {init ? slidersComps : null}
      </div>

      {/* {!toggler ? <ExistingLonResult /> : <NewJobResult />} */}
      {init ? <LonResult toggler={toggler} inIframe={inIframe} /> : ""}
    </div>

  );
}

export default App;

