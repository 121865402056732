import React from 'react';

const Footer = () => {


    return (
        <div id="copyright">
            <p>Copyright ©2021 PARETIFY</p>
        </div>
    );
}

export default Footer;